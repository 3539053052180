@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body,html{
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}
body{
    background-color: #e6e6e6;
    background-image: url(/public/img/background.webp);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    background-attachment: fixed;
}
header{
    background: #000;
    padding: 1.5em;
    padding-left: 5em;
    padding-right: 5em;
}
header nav{
    max-width: 1970px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.special{
    margin: 10px auto;
}
.menu img{
    width: 40px;
    cursor: pointer;
}
.logo-fm img{
    width: 100px;
}
.menu-content{
    position: fixed;
    top: 0;
    height: 100%;
    background-color: #000;
    width: 60%;
    z-index: 1000;
    margin-left: -300px;
    display: none;
    transition: all .4s;
}
.menu-content .header-menu{
   display: flex;
   justify-content: end;
}
.menu-content .header-menu .close{
    margin-top: 20px;
    margin-right: 20px;
    background: #ffffff;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: all .3s;
    cursor: pointer;
}
.menu-content .header-menu .close p{
    font-weight: 800;
    transition: all .3s;
}
.menu-content .header-menu .close:hover{
    background: #ff4f00;
    width: 38px;
    height: 38px;
}
.menu-content .header-menu .close:hover p{
    color: #ffffff;
}
.menu-content .body-menu {
    margin-top: 20px;
}
.menu-content .body-menu a{
    display: block;
    color: #e6e6e6;
    text-decoration: none;
    font-size: 20px;
    border-bottom: solid 1px #c7c7c7;
    padding-bottom: 10px;
    padding-top: 10px;
    transition: all .3s;
    padding-left: 15px;
}
.menu-content .body-menu a:hover{
    background: #ff4f00;
    color: #fff;
}
.logo-kicks{
    width: 100%;
    text-align: center;
    margin-top: 2em;
}
.logo-kicks img{
    position: relative;
    width: 300px;
    z-index: 100;
}
.cuadro1 img{
    position: absolute;
    width: 50px;
    transform: rotate(180deg);
    top: 120px;
    left: 100px;
}
.cuadro2 img{
    position: absolute;
    width: 50px;
    bottom: 30px;
    right: 100px;
}
.formulario{
    width: 100%;
    display: flex;
    justify-content: center;
}
input:focus{
    outline: none;
    border: solid 1px #ff4f00;
}
textarea:focus{
    outline: none;
    border: solid 1px #ff4f00;
}
select:focus{
    outline: none;
    border: solid 1px #ff4f00;
}
.formulario form{
    margin-top: -1.5em;
    width: 550px;
    background: #fff;
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 3em;
    border-radius: 10px;
    border: solid 1px #c7c7c7;
}
form label{
    display: block;
    color: #424446;
    margin-bottom: 10px;
}
form input{
    color: #000000;
    width: 100%;
    height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
    border: solid 1px #898b8d;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}
form .form-group-col{
    display: flex;
}
.col-2{
    width: 50%;
}
.col-tel input{
    width: 85%;
}
form input::placeholder{
    color: #898b8d;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}
form .form-group{
    margin-bottom: 1em;
}
form .form-group-col{
    margin-bottom: 1em;
}
form .form-group-radio{
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-group-radio label{
    margin-bottom: 0px;
}
.form-group-radio input{
    margin-top: -2px;
    width: auto;
    
}
.group-radio{
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
form textarea{
    color: #000000;
    width: 100%;
    padding-left: 10px;
    padding-top: 10px;
    padding-right: 10px;
    border-radius: 10px;
    border: solid 1px #898b8d;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}
form select{
    color: #000000;
    width: 104%;
    height: 35px;;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    border: solid 1px #898b8d;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}
form .form-group-check{
    display: flex;
    align-items: center;
    justify-content: center;
}
.form-group-check input{
    width: auto;
    margin-top: -7px;
}
.form-group-check a{
    color: #05b5ff;
}
form button{
    margin-top: 20px;
    width: 104%;
    border-radius: 50px;
    height: 50px;
    border: 0;
    background: #ff4f00;
    color: #fff;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 18px;
}
.logos-nissan{
    text-align: center;
}
.logos-nissan img{
    margin-top: 40px;
    width: 250px;
}
footer{
    width: 100%;
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
}
.gracias{
    margin-top: 50px;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}
.gracias span{
    color: #ff4f00;
}
.gracias a{
    text-decoration: none;
    border-radius: 10px;
    background: #ff4f00;
    color: #fff;
    padding: 10px 30px 10px 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
    transition: all .3s ease-in-out;
}
.gracias a:hover{
    background: #fff;
    color: #ff4f00;
}
@media (max-width: 1000px){
    .cuadro1 img{
        left: 30px;
    }
    .cuadro2 img{
        right: 30px;
    }
}
@media (max-width: 800px){
    .menu-content{
        width: 80%;
    }
    .menu-content .body-menu a{
        font-size: 18px;
    }
    header{
        padding-left: 1.5em;
        padding-right: 1.5em;
    }
    .cuadro1 img{
        display: none;
    }
    .cuadro2 img{
        display: none;
    }
    .formulario form{
        width: 70%;
    }
}
@media (max-width: 450px){
    form .form-group-col{
        display: block;
    }
    .col-2{
        width: 100%;
    }
    .col-tel input{
        margin-bottom: 20px;
        width: 100%;
    }
    form .form-group-radio{
        display: block;
        text-align: center;
    }
    form .form-group-radio .group-radio{
        display: inline-flex;
    }
    form select{
        width: 106%;
    }
    form button{
        width: 106%;
    }
}


.lightbox-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .lightbox-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 80%;
    text-align: center;
  }
  
  .lightbox-content button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .lightbox {
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      transition: opacity 300ms;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      transition: opacity 300ms;
    }
  }


  
  button:disabled {
    background-color: #CCCCCC;
    cursor: not-allowed;
  }
  
  /* Loader styles */
  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #007BFF;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* Animation styles */
  .participants-enter {
    opacity: 0;
    transform: translateY(-10px);
  }
  .participants-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 300ms, transform 300ms;
  }
  .participants-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .participants-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 300ms, transform 300ms;
  }
  



  .locked-layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    text-align: center;
    font-size: 1.5em;
  }
  